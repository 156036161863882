import { isBrowser } from "@/utils/env";

export const getSessionTypeFromUrl = () => {
  const urlParams = new URLSearchParams(isBrowser() ? window.location.search : {});
  return urlParams.get("session_type") || "";
};

/** Use for formatting anchor link for gatsby`s navigate() fns and <Link> component.
 *  Gatsby can`t redirect to anchor, and it causes troubles like your/url/#anchor
 * That fn was written for formatting such links to your/url#anchor
 * _ */
export const formatSeoSafeAnchorLink = (link: string, pathname: string) =>
  (link.startsWith("#") ? pathname + link : link);

/**
 * Utility function to retrieve a URL parameter by its key.
 * Extracts the specified query parameter from the current page's URL and returns its value.
 * Useful for cases where dynamic data in the URL needs to be accessed in the code.
 * @param {string} key - The name of the query parameter to retrieve.
 * @returns {string|null} - The value of the query parameter or null if not found.
 */

export const getUrlParameterByKey = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};
